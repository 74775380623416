import React from 'react'

function Lines() {
    return (
        <div className="ahdc-main__lines">
            <div className="ahdc-main__line ahdc-main__line--1"></div>
            <div className="ahdc-main__line ahdc-main__line--2"></div>
            <div className="ahdc-main__line ahdc-main__line--3"></div>
            <div className="ahdc-main__line ahdc-main__line--4"></div>
            <div className="ahdc-main__line ahdc-main__line--5"></div>
        </div>
    )
}

export default Lines
