import React, { useRef, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Lines from "./components/Lines";
import "./scss/styles.scss";
import gsap from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { onScrollProgressBar } from "./animations/desktop";
import ScrollToTop from "./components/ScrollToTop";
import LocaleContext from "./context/LocaleContext";
import { Helmet } from "react-helmet-async";

const Home = React.lazy(() => import("./pages/Home"));
const Work = React.lazy(() => import("./pages/Work"));
const Playground = React.lazy(() => import("./pages/Playground"));
const About = React.lazy(() => import("./pages/About"));
const Contact = React.lazy(() => import("./pages/Contact"));
const WorkDetail = React.lazy(() => import("./pages/WorkDetail"));
const PageNotFound = React.lazy(() => import("./pages/PageNotFound"));

gsap.registerPlugin(CSSPlugin, CSSRulePlugin, ScrollTrigger);

function App() {
  const wrapper = useRef(null);
  const main = useRef(null);
  const progress = useRef(null);
  const pageX = useRef(null);
  const pageY = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    onScrollProgressBar(progress, main);
    document.body.setAttribute("id", "scrollToTop");
  }, []);

  useEffect(() => {
    window.document.addEventListener("mousemove", (e) => {
      pageX.current = e.pageX;
      pageY.current = e.pageY;
    });
  });

  return (
    <>
      <Helmet>
        <title>
          {pathname === "/"
            ? `HOME | Alaa Hassoun`
            : `${pathname
                .replace("/", " ")
                .replace(/[/]/g, " ")
                .toUpperCase()} | Alaa Hassoun`}
        </title>
        <meta
          name="description"
          content={`Hello and welcome to my portfolio website. ${
            pathname === "/"
              ? `HOME | Alaa Hassoun`
              : `${pathname
                  .replace("/", " ")
                  .replace(/[/]/g, " ")
                  .toUpperCase()} | Alaa Hassoun`
          }`}
        />
        <link rel="canonical" href={`https://www.alaahassoun.com${pathname}`} />
      </Helmet>
      <LocaleContext.Provider value={{ pageX, pageY }}>
        <div className="ahdc-main" ref={main}>
          <ScrollToTop>
            <div className="ahdc-main__progress" ref={progress}></div>
            <Lines />
            <div className="ahdc-main__wrapper" ref={wrapper}>
              <React.Suspense fallback={false}>
                <Switch>
                  <Route exact path="/">
                    <Home wrapper={wrapper} />
                  </Route>

                  <Route exact path="/work">
                    <Work wrapper={wrapper} />
                  </Route>

                  <Route exact path="/playground">
                    <Playground wrapper={wrapper} />
                  </Route>

                  <Route path="/about">
                    <About type="about" wrapper={wrapper} />
                  </Route>

                  <Route path="/contact">
                    <Contact wrapper={wrapper} />
                  </Route>

                  <Route path="/work/:id">
                    <WorkDetail type="projects" wrapper={wrapper} />
                  </Route>

                  <Route path="/playground/:id">
                    <WorkDetail type="playground" wrapper={wrapper} />
                  </Route>

                  <Route path="*">
                    <PageNotFound />
                  </Route>
                </Switch>
              </React.Suspense>
            </div>
          </ScrollToTop>
        </div>
      </LocaleContext.Provider>
    </>
  );
}

export default App;
