import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { CSSPlugin } from "gsap/CSSPlugin";

gsap.registerPlugin(CSSPlugin, CSSRulePlugin, ScrollTrigger);

// LOAD ANIMATIONS
export function loadAnimations(tl, pathname, container, screen, logo, content) {
  handleCursorLeave();
  tl
  .to(container.current, {
    duration:0.1,
    opacity:1,
    ease: "none",
  })
  .from(screen.current, {
    duration: 1.2,
    translateX: "-100%",
    translateY: "0%",
    ease: "power3.inOut",
  })
    .from(logo.current, {
      duration: 1.2,
      translateX: "-50%",
      translateY: "200%",
      opacity: 0,
      ease: "power3.inOut",
    })
    .to(logo.current, {
      duration: 1.2,
      opacity: 0,
      translateX: "-50%",
      translateY: "-200%",
      ease: "power3.inOut",
    })
    .to(screen.current, {
      duration: 1.2,
      translateX: "-100%",
      translateY: "0%",
      ease: "power3.out",
      delay: 0.3,
      onComplete: () => {
        content.current.style.opacity = "1";
        container.current.style.display = "none";
        menuAnimation(tl);
        if (pathname === "/") {
          heroAnimations(tl);
        } else {
          customPageHeroAnimation(tl);
        }
        ScrollTrigger.refresh();
      },
    });
}

// HERO ANIMATIONS
export function heroAnimations(tl) {
  gsap.from(".ahdc-hero__text", {
    duration: 1.3,
    translateX: "0px",
    translateY: "100px",
    stagger: 0.1,
    opacity: 0,
    ease: "power3.inOut",
  });

  gsap.from(".ahdc-hero__img", {
    duration: 1.3,
    opacity: 0,
    // scale:'0.5',
    ease: "power3.inOut",
  });

  gsap.from(".ahdc-hero .ahdc-link", {
    duration: 1.3,
    stagger: 0.1,
    translateX: "0px",
    translateY: "100px",
    opacity: 0,
    ease: "power3.inOut",
    delay: 0.3,
  });

  gsap.from(".ahdc-hero__mark-box", {
    duration: 1.3,
    opacity: 0,
    ease: "power3.inOut",
    delay: 0.5,
  });
}

export function onScrollHeroAnimations(tl, myImg, columnLeft, hero) {
  tl.fromTo(
    myImg.current,
    {
      opacity: 1,
    },
    {
      duration: 1,
      opacity: 0,
      ease: "power3.out",
      scrollTrigger: {
        trigger: hero.current,
        scrub: true,
        markers: false,
        start: "top top",
      },
    }
  ).to(
    columnLeft.current,
    {
      duration: 5,
      opacity: 0,
      ease: "power3.out",
      scrollTrigger: {
        trigger: hero.current,
        scrub: true,
        markers: false,
        start: "top top",
      },
    },
    "-=1"
  );
}

// MENU ANIMATIONS

export function menuAnimation(tl) {
  tl.from(".ahdc-menu", {
    duration: 0.5,
    opacity: 0,
    ease: "power3.in",
  });
}

export function menuAnimationOpen(tl) {
  tl.to(".ahdc-menu__toggle-line--1", {
    duration: 0.9,
    translateX: "2px",
    translateY: "7px",
    rotate:"45deg",
    ease: "elastic.out(1, 0.4)",
  }).to(
    ".ahdc-menu__toggle-line--2",
    {
      duration: 0.9,
      translateX: "2px",
      translateY: "-5px",
      rotate:"-45deg",
      ease: "elastic.out(1, 0.4)",
    },
    "-=.9"
  );
}

export function menuAnimationClose(tl) {
  tl.to(".ahdc-menu__toggle-line--1", {
    duration: 0.9,
    translateX: "0px",
    translateY: "0px",
    rotate:"0deg",
    ease: "elastic.out(1, 0.4)",
  }).to(
    ".ahdc-menu__toggle-line--2",
    {
      duration: 0.9,
      translateX: "0px",
      translateY: "0px",
      rotate:"0deg",
      ease: "elastic.out(1, 0.4)",
    },
    "-=.9"
  );
}

// NAV ANIMATIONS

export function navAnimationOpen(tl) {
  gsap.set("body", {
    overflowY: "hidden",
  });

  tl.to(".ahdc-menu__wrapper", {
    duration: 0.5,
    opacity: 1,
    display: "flex",
    ease: "power3.out",
  })
    .to(".ahdc-menu__overlay", {
      duration: 0.7,
      stagger: 0.7,
      translateX: "0px",
      translateY: "0px",
      ease: "power3.inOut",
    })
    .from(".ahdc-menu__link", {
      duration: 0.7,
      translateX: "-200px",
      translateY: "0px",
      stagger: 0.2,
      opacity: 0,
      ease: "power3.out",
    })
    .from(
      ".ahdc-menu__sm-item",
      {
        duration: 0.7,
        ease: "power3.out",
        opacity: 0,
      },
      "-=.7"
    )
    .to(
      ".ahdc-menu__sm-mask",
      {
        duration: 0.9,
        ease: "power3.out",
        translateX: "100%",
        translateY: "0px",
        rotate:"0deg",
        stagger: 0.1,
      },
      "-=.7"
    )
    .from(
      ".ahdc-menu__copyright",
      {
        duration: 0.9,
        ease: "power3.out",
        opacity: 0,
      },
      "-=.9"
    );
}

export function navAnimationClose(tl) {
  gsap.set("body", {
    overflowY: "unset",
  });

  tl.to(".ahdc-menu__wrapper", {
    duration: 0.5,
    opacity: 0,
    ease: "power3.out",
  })
    .to(".ahdc-menu__overlay", {
      duration: 0.7,
      stagger: 0.5,
      translateX: "-100%",
      translateY: "0px",
      ease: "power3.out",
    })
    .to(".ahdc-menu__sm-mask", {
      duration: 0.7,
      ease: "power3.out",
      translateX: "0%",
      translateY: "0px",
      onComplete: () =>
        (document.querySelector(".ahdc-menu__wrapper").style.display = "none"),
    });
}

// BGIMG ANIMATIONS

export function handleNavLinkIn(bg, bgImg) {
  gsap.to(bgImg.current, {
    duration: 0.3,
    backgroundImage: `url(${bg})`,
    opacity: 1,
    translateX: "0px",
    translateY: "0px",
    scale:"1",
    ease: "linear",
  });
}

export function handleNavLinkOut(bg,bgImg) {
  gsap.to(bgImg.current, {
    duration: 0.3,
    opacity: 0,
    backgroundImage: `url(${bg})`,
    translateX: "0px",
    translateY: "0px",
    rotate:"1.2",
    ease: "linear",
  });
}

// CURSOR
const rule = CSSRulePlugin.getRule(".cursor:before");

export function handleCursorEnter() {
  gsap.to(rule, {
    duration: 0,
    width: "100px",
    height: "100px",
    ease: "none",
  });
}

export function handleCursorLeave() {
  gsap.to(rule, {
    duration: 0,
    width: "50px",
    height: "50px",
    ease: "none",
  });
}

// Project Hover

export function handleProjectEnter(title,index) {
  gsap.to(`.ahdc-workblock__work--${title}-${index}`, {
    duration: .5,
    translateX: "0px",
    translateY: "-15px",
    ease: "power3.out",
  });

  gsap.to(`.ahdc-workblock__work--${title}-${index} .ahdc-workblock__text`, {
    duration: .5,
    opacity: 1,
    translateX: "0px",
    translateY: "0px",
    ease: "power3.out",
  });

}

export function handleProjectLeave(title,index) {
  gsap.to(`.ahdc-workblock__work--${title}-${index}`, {
    duration: .8,
    translateX: "0px",
    translateY: "0px",
    ease: "power3.out",
  });

  gsap.to(`.ahdc-workblock__work--${title}-${index} .ahdc-workblock__text`, {
    duration: .5,
    opacity: 0,
    translateX: "-15px",
    translateY: "0px",
    ease: "power3.out",
  });
}

// CUSTOMLINK ANIMATIONS
export function handleCustomLinkIn(customLink, customLinkMask) {
  handleCursorEnter();

  gsap.to(customLink.current, {
    duration: 0.7,
    color: "#0c0c0c",
    ease: "power3.inOut",
  });

  gsap.fromTo(
    customLinkMask.current,
    {
      duration: 0.7,
      translateX: "-100%",
      translateY: "0px",
      ease: "power3.inOut",
    },
    {
      duration: 0.7,
      translateX: "0%",
      translateY: "0px",
      rotate:"0deg",
      ease: "power3.inOut",
    }
  );
}

export function handleCustomLinkOut(customLink, customLinkMask) {
  handleCursorLeave();

  gsap.to(customLink.current, {
    duration: 0.7,
    color: "#fff",
    ease: "power3.inOut",
  });

  gsap.fromTo(
    customLinkMask.current,
    {
      duration: 0.7,
      translateX: "0%",
      translateY: "0px",
      ease: "power3.inOut",
    },
    {
      duration: 0.7,
      translateX: "100%",
      translateY: "0px",
      rotate:"0deg",
      ease: "power3.inOut",
    }
  );
}

// PROJECTS ANIMATIONS
export function onScrollProjectsHeadingAnimations(heading, pathname) {
  if (pathname === "/") {
    gsap.fromTo(
      heading.current,
      {
        translateX: "100%",
        translateY: "0px",
        rotate:"0deg",
      },
      {
        duration: 1,
        translateX: "0%",
        translateY: "0px",
        rotate:"0deg",
        ease: "power3.out",
        scrollTrigger: {
          trigger: heading.current,
          scrub: true,
        },
      }
    );
  }
}

export function onScrollProjectsAnimations(title, pathname) {
  gsap.fromTo(
    `.ahdc-workblock__work--${title}`,
    {
      opacity: 0,
      translateX: "0px",
      translateY: "100px",
      scale:"1",
      transform:"skew(0)"
    },
    {
      duration: 1,
      opacity: 1,
      translateX: "0px",
      translateY: "0px",
      scale:"1",
      transform:"skew(0)",
      ease: "power3.out",
      stagger: 0.3,
      scrollTrigger: {
        trigger: `.ahdc-workblock__work--${title}`,
        start: "top center+=300",
        toggleActions: "play none none reverse",
        markers: false,
      },
    }
  );
  gsap.fromTo(
    `.ahdc-workblock__work--${title} .ahdc-workblock__img`,
    {
      translateX: "0px",
      translateY: "0px",
      scale:"1.2",
      transform:"skew(0)",
    },
    {
      duration: 1,
      translateX: "0px",
      translateY: "0px",
      scale:"1",
      transform:"skew(0)",
      ease: "power3.out",
      stagger: 0.3,
      scrollTrigger: {
        trigger: `.ahdc-workblock__work--${title}`,
        start: "top center+=300",
        toggleActions: "play none none reverse",
        markers: false,
      },
    }
  );

  gsap.fromTo(
    `.ahdc-workblock__work--${title} .ahdc-workblock__title`,
    {
      opacity: 0,
      translateX: "0px",
      translateY: "100px",
      scale:"1",
      transform:"skew(10deg,0)",
    },
    {
      duration: 1,
      opacity: 1,
      translateX: "0px",
      translateY: "0px",
      scale:"1",
      transform:"skew(0,0)",
      ease: "power3.out",
      stagger: 0.3,
      scrollTrigger: {
        trigger: `.ahdc-workblock__work--${title}`,
        start: "top center+=300",
        toggleActions: "play none none reverse",
        markers: false,
      },
    }
  );

  if (pathname === "/") {
    gsap.fromTo(
      `.ahdc-workblock--${title} .ahdc-workblock__link-box`,
      {
        opacity: 0,
        translateX: "0px",
        translateY: "100px",
      },
      {
        duration: 1,
        opacity: 1,
        translateX: "0px",
        translateY: "0px",
        ease: "power3.out",
        scrollTrigger: {
          trigger: `.ahdc-workblock--${title} .ahdc-workblock__link-box`,
          start: "top center+=400",
          toggleActions: "play none none reverse",
          markers: false,
        },
      }
    );
  }
}

// PROGRESS BAR ANIMATION APP
export function onScrollProgressBar(progress, main) {
  gsap.fromTo(
    progress.current,
    {
      translateX: "0px",
      translateY: "-100%",
    },
    {
      duration: 1,
      translateX: "0px",
      translateY: "0px",
      ease: "none",
      scrollTrigger: {
        scrub: true,
      },
    }
  );
}

// CUSTOM PAGE HERO ANIMATION
export function customPageHeroAnimation(tl) {
  tl.from(".ahdc-pagehero__bg", {
    duration: 1,
    opacity: 0,
    ease: "power3.inOut",
  }).from(
    ".ahdc-pagehero__title .ahdc-pagehero__char",
    {
      duration: 0.7,
      opacity: 0,
      translateX: "0px",
      translateY: "100px",
      transform:"skew(0,45deg)",
      stagger: 0.05,
      ease: "power4.out",
    },
    "-=1"
  );
}

export function onScrollcustomPageHeroAnimation(
  tl,
  container,
  heading,
  background
) {
  tl.fromTo(
    background.current,
    {
      opacity: 0.1,
    },
    {
      duration: 1,
      opacity: 0,
      ease: "power3.out",
      scrollTrigger: {
        trigger: container.current,
        scrub: true,
        markers: false,
        start: "top top",
      },
    }
  ).to(
    heading.current,
    {
      duration: 5,
      opacity: 0,
      ease: "power3.out",
      scrollTrigger: {
        trigger: container.current,
        scrub: true,
        markers: false,
        start: "top top",
      },
    },
    "-=1"
  );
}

// WORK DETAILS ANIMATIONS

export function onScrollWorkDetailsAnimation(work, loading, imagesBlock) {
  if (loading === false) {
    gsap.from([".ahdc-work__column--left h3", ".ahdc-work__column--left p"], {
      duration: 1,
      opacity: 0,
      translateX: "-100px",
      translateY: "0",
      stagger: 0.09,
      ease: "power3.out",
      scrollTrigger: {
        trigger: work.current,
        start: "top center+=150",
        toggleActions: "play none none reverse",
        markers: false,
      },
    });

    gsap.from(
      [
        ".ahdc-work__column--right .ahdc-work__item .ahdc-work__heading",
        ".ahdc-work__column--right .ahdc-work__item .ahdc-work__text",
      ],
      {
        duration: 1,
        opacity: 0,
        translateX: "-100px",
        translateY: "0",
        ease: "power3.out",
        stagger: 0.09,
        scrollTrigger: {
          trigger: work.current,
          start: "top center+=150",
          toggleActions: "play none none reverse",
          markers: false,
        },
      }
    );

    const workImages = Array.from(imagesBlock.current.children);

    workImages.forEach((image, index) => {
      gsap.from(
        `.ahdc-work__image-wrapper--${index} .ahdc-work__image--${index}`,
        {
          duration: 1,
          opacity: 0,
          translateX: "0px",
          translateY: "100px",
          ease: "power3.out",
          scrollTrigger: {
            trigger: `.ahdc-work__image-wrapper--${index}`,
            start: "top center+=300",
            toggleActions: "play none none reverse",
            markers: false,
          },
        }
      );
    });

    gsap.fromTo(
      `.ahdc-work__link-wrapper`,
      {
        opacity: 0,
        translateX: "0px",
        translateY: "100px",
      },
      {
        duration: 1,
        opacity: 1,
        translateX: "0px",
        translateY: "0px",
        ease: "power3.out",
        scrollTrigger: {
          trigger: `.ahdc-work__link-wrapper`,
          start: "top center+=400",
          toggleActions: "play none none reverse",
          markers: false,
        },
      }
    );
  }
}

// ABOUT BLOCK ANIMATIONS
export function onScrollAboutAnimations(
  loading,
  about,
  myImage,
  myDescription
) {
  if (loading === false) {
    gsap.fromTo(
      [myImage.current, myDescription.current],
      {
        opacity: 0,
        translateX: "0px",
        translateY: "100px",
      },
      {
        duration: 1,
        translateX: "0px",
        translateY: "0px",
        opacity: 1,
        stagger: 0.3,
        ease: "power3.out",
        scrollTrigger: {
          trigger: about.current,
          start: "top center+=100",
          toggleActions: "play none none reverse",
          markers: false,
        },
      }
    );
  }
}


// 404  ANIMATIONS
export function pageNotFoundAnimation() {
    gsap.from(
      ".ahdc-page-not-found--animate",
      {
        duration: 1.5,
        opacity: 0,
        translateX: "0px",
        translateY: "100px",
        stagger: 0.2,
        delay:0.2,
        ease: "power3.out",
      }
    );
}


/**
 * Not
 * Used
 * Animations
 */

// INTRO ANIMATIONS
export function introAnimations(tl, wrapper, main, intro, pathname) {
  tl.from("body", {
    duration: 0.1,
    opacity: 0,
  })
    .to(wrapper.current, {
      duration: 0,
      opacity: 0,
      css: {
        display: "none",
      },
    })
    .from(".ahdc-intro__logo", {
      duration: 1.5,
      opacity: 0,
      y: 200,
      ease: "power3.out",
    })
    .to(".ahdc-intro__overlay--white-left", {
      duration: 3.5,
      top: "100%",
      ease: "power1.out",
    })
    .to(
      ".ahdc-intro__overlay--white-right",
      {
        duration: 3.5,
        bottom: "100%",
        ease: "power1.out",
      },
      "-=3.5"
    )
    .to(
      ".ahdc-intro__overlay--black-left",
      {
        duration: 3.5,
        top: "100%",
        ease: "power1.out",
      },
      "-=3.5"
    )
    .to(
      ".ahdc-intro__overlay--black-right",
      {
        duration: 3.5,
        bottom: "100%",
        ease: "power1.out",
      },
      "-=3.5"
    )
    .to(
      ".ahdc-intro__logo",
      {
        duration: 1.5,
        opacity: 0,
        ease: "power3.out",
        onComplete: () => {
          if (pathname === "/") {
            wrapper.current.style.display = "block";
            main.current.style.height = "100%";
            intro.current.style.display = "none";
            heroAnimations(tl);
            ScrollTrigger.refresh();
          } else {
            wrapper.current.style.display = "block";
            main.current.style.height = "100%";
            intro.current.style.display = "none";
            customPageHeroAnimation(tl);
            ScrollTrigger.refresh();
          }
        },
      },
      "-=.5"
    );
}

// CUSTOMLINK ANIMATIONS

export function handleCustomLinkTwoIn(customLink, customLinkBorder) {
  gsap.to(customLink.current, {
    duration: 0.3,
    color: "#f2f2f2",
    ease: "power3.inOut",
  });

  gsap.fromTo(
    customLinkBorder.current,
    {
      duration: 0.3,
      left: "-100%",
      ease: "power3.inOut",
    },
    {
      duration: 0.3,
      left: "0%",
      ease: "power3.inOut",
    }
  );
}

export function handleCustomLinkTwoOut(customLink, customLinkBorder) {
  gsap.to(customLink.current, {
    duration: 0.3,
    color: "#fff",
    ease: "power3.out",
  });

  gsap.fromTo(
    customLinkBorder.current,
    {
      duration: 0.3,
      left: "0%",
      ease: "power3.out",
    },
    {
      duration: 0.3,
      left: "100%",
      ease: "power3.out",
    }
  );
}
