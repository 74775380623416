import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Router>
        <App />
      </Router>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

